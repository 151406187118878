<template>
    <div class="col-sm-12 mt-4">
        <ValidationObserver v-slot="{ passes }">
            <form class="form-auth-small" @submit.prevent="passes(sendbyBank)">
                <ValidationProvider name="cor" rules="required" v-slot="{ errors}">
                    <div class="country-dropdown">
                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':paybyBank.countryofResidence && !errors[0]}">
                            <span class="float-label">
                            <label for="Country">Country of Residence*</label>
                                <multiselect
                                    placeholder=""
                                    v-model="paybyBank.countryofResidence"
                                    :show-labels="false" 
                                    :options="countryofResidenceOptions" 
                                    :searchable="false"
                                    label="name" 
                                    track-by="name">
                                    <template slot="option" slot-scope="props">
                                        <span :class="props.option.flagClass"></span>
                                        <div class="option__desc">
                                            <span class="option__title">{{ props.option.name }}</span>
                                        </div>
                                    </template>
                                </multiselect>  
                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                <i class="fa fa-check" v-if="paybyBank.countryofResidence && !errors[0]"></i>
                            </span>
                        </div>
                    </div>
                </ValidationProvider>
                <ValidationProvider name="destination-bank" rules="required" v-slot="{ errors }">
                    <div class="country-dropdown">
                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':paybyBank.destinationBank && !errors[0]}">
                            <span class="float-label">
                            <label for="Country">Destination Bank*</label>
                                <multiselect
                                    placeholder=""
                                    v-model="paybyBank.destinationBank" 
                                    :show-labels="false" 
                                    :options="destinationBankOptions" 
                                    :searchable="false" 
                                    label="name" 
                                    track-by="name">
                                    <template slot="option" slot-scope="props">
                                        <img :src="props.option.flagClass">
                                        <div class="option__desc">
                                            <span class="option__title">{{ props.option.name }}</span>
                                        </div>
                                    </template>
                                </multiselect> 
                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                <i class="fa fa-check" v-if="paybyBank.destinationBank && !errors[0]"></i>
                            </span>
                        </div>
                    </div>
                </ValidationProvider>
                <ValidationProvider name="branch" rules="required" v-slot="{ errors }">
                    <div class="country-dropdown">
                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':paybyBank.branch && !errors[0]}">
                            <span class="float-label">
                            <label for="Country">Branch*</label>
                                <multiselect
                                    placeholder=""
                                    v-model="paybyBank.branch" 
                                    :show-labels="false" 
                                    :options="destinationBankOptions" 
                                    :searchable="false" 
                                    label="name" 
                                    track-by="name">
                                    <template slot="option" slot-scope="props">
                                        <img :src="props.option.flagClass">
                                        <div class="option__desc">
                                            <span class="option__title">{{ props.option.name }}</span>
                                        </div>
                                    </template>
                                </multiselect> 
                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                <i class="fa fa-check" v-if="paybyBank.branch && !errors[0]"></i>
                            </span>
                        </div>
                    </div>
                </ValidationProvider>
                <ValidationProvider name="currency" rules="required" v-slot="{ errors}">
                    <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !paybyBank.currency,'success':paybyBank.currency}">
                        <span class="float-label">
                            <label for="functions">Account Currency*</label>
                            <multiselect
                                placeholder=""
                                v-model="paybyBank.currency"  
                                :show-labels="false" 
                                :options="currencyOptions" 
                                :searchable="false"
                                label="name" 
                                track-by="name" >
                                <template slot="option" slot-scope="props">
                                    <div class="option-title">
                                        <span class="mr-2" :class="props.option.flagClass"></span>
                                        <span>{{ props.option.name }}</span>
                                    </div>
                                </template>
                            </multiselect> 
                            <i class="fa fa-times" v-if="errors.length && !paybyBank.currency"></i>
                            <i class="fa fa-check" v-if="paybyBank.currency"></i>
                        </span>
                    </div>
                </ValidationProvider>
                <ValidationProvider name="accountNumber" rules="required" v-slot="{ errors}" vid="confirmation">
                    <div class="form-group" :class="{'errors': errors.length && !paybyBank.accountNumber,'success':paybyBank.accountNumber && !errors[0]}">
                        <span class="float-label">
                            <input type="text" v-model="paybyBank.accountNumber" class="form-control" name="accountNumber" id="accountNumber" placeholder="Account Number*" @keypress="enterOnlyNumber($event)">
                            <label for="accountNumber" class="control-label">Account Number*</label>
                            <div class="line"></div>
                            <i class="fa fa-times" v-if="errors.length && !paybyBank.accountNumber "></i>
                            <i class="fa fa-check" v-if="paybyBank.accountNumber && !errors[0]"></i>
                        </span>
                    </div>
                </ValidationProvider>
                <ValidationProvider name="confirm_accountNumber" rules="required|confirmed:confirmation" v-slot="{ errors}">
                    <div class="form-group" :class="{'errors': errors.length && !paybyBank.confirm_accountNumber || errors[0],'success':paybyBank.confirm_accountNumber && !errors[0]}">
                        <span class="float-label">
                            <input type="text" v-model="paybyBank.confirm_accountNumber" class="form-control" name="confirmation_password" id="confirm_accountNumber" placeholder="Confirm Account Number*" @keypress="enterOnlyNumber($event)">
                            <label for="confirm_accountNumber" class="control-label">Confirm Account Number*</label>
                            <div class="line"></div>
                            <i class="fa fa-times" v-if="errors.length && !paybyBank.confirm_accountNumber || errors[0] "></i>
                            <i class="fa fa-check" v-if="paybyBank.confirm_accountNumber && !errors[0]"></i>
                        </span>
                    </div>
                </ValidationProvider>
                
                <div class="d-flex justify-content-between align-items-center m-b-20">
                    <p class="mb-0">Save this account for future use.</p>
                    <div class="custom-switch success-error-bg">
                        <input type="checkbox" class="custom-switch-input" id="tamount" v-model="paybyBank.futureUseIs">
                        <label class="custom-switch-label" for="tamount">
                            <span class="custom-switch-text1">Yes</span>
                            <span class="custom-switch-text2">No</span>
                        </label>
                    </div>
                </div>
                <div class="phone-with-country d-flex justify-content-between align-items-center disabled">
                    <div class="select-country">
                        <div class="form-group form-select single-multiselect">
                            <span class="float-label">
                                <label for="amount">Amount</label>
                                <multiselect
                                    placeholder=""
                                    v-model="paybyBank.amountFlag"   
                                    :show-labels="false" 
                                    :options="currencyOptions"
                                    :searchable="false"
                                    label="name" 
                                    track-by="name">
                                    <!-- disabled -->
                                    <template slot="singleLabel" slot-scope="props">
                                        <span class="mr-2" :class="props.option.flagClass"></span>
                                        <span class="option-title">
                                            <span>{{ props.option.name }}</span>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        <div class="option-title">
                                            <span class="mr-2" :class="props.option.flagClass"></span>
                                            <span>{{ props.option.name }}</span>
                                        </div>
                                    </template>
                                </multiselect> 
                            </span>
                        </div>
                    </div>
                    <div class="add-number">
                        <div class="form-group">
                            <span class="float-label">
                                <input type="text" v-model="paybyBank.amount" class="form-control" id="amount" placeholder="0.00" @keypress="enterOnlyNumber($event)">
                                <div class="line"></div>
                            </span>
                        </div>
                    </div>
                </div>
                <button type="submit" class="btn btn-gradient btn-lg btn-mw btn-block">Pay by Bank</button>
            </form> 
        </ValidationObserver>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider } = require("vee-validate")
export default {
    name:'PaybyBank',
    components: {
        ValidationObserver,
        ValidationProvider,
        Multiselect: () => import('vue-multiselect'),
    },data(){    
        return{
            paybyBank:{
                countryofResidence: null,
                branch:null,
                currency:null,
                futureUseIs:true,
                amountFlag:{ name: 'USD', flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                amount:500,
                accountNumber: '',
                confirm_accountNumber:''
            },

            currencyOptions:[
                { name: 'GBP', flagClass: "flag-icon flag-icon-gb fi-md fi-round"},
                { name: 'GHS', flagClass: "flag-icon flag-icon-gh fi-md fi-round"},
                { name: 'USD', flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                { name: 'RUB', flagClass:"flag-icon flag-icon-ru fi-md fi-round"},
            ],

            countryofResidenceOptions: [
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],

            destinationBankOptions: [
                { name: 'Consolidated Bank', flagClass:require(`@/assets/open-account/Consolidated_Bank.png`) },
                { name: 'Guaranty Trust Bank (Ghana) Limited', flagClass:require(`@/assets/open-account/Guaranty_Trust_Bank.png`)},
                { name: 'HFC Bank (Ghana) Limited', flagClass:require(`@/assets/open-account/HFC_Bank.jpg`) },
                { name: 'Universal Merchant Bank (UMB)' , flagClass:require(`@/assets/open-account/Universal_Merchant_Bank.jpg`) },
            ]
        }
    },methods:{
        sendbyBank(){
            this.$router.push({ path: '/payment/payment-transfer' })
        },

        enterOnlyNumber(event) {
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if (keyCode < 48 || keyCode > 57) { 
                event.preventDefault();
            }
        }
    }
}
</script>
